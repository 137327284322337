import { PARENT_MODAL_DOMAIN, modalMessages } from "../constants/responses";

const handleSuccess = () => {
  window.parent.postMessage(
    { message: modalMessages.SUCCESS },
    PARENT_MODAL_DOMAIN
  );
};

export const handlePendingReviewManual = () => {
  window.parent.postMessage(
    { message: modalMessages.PENDING_REVIEW_MANUAL },
    PARENT_MODAL_DOMAIN
  );
};

export default handleSuccess;
